import styled from "@emotion/styled"
import { graphql, navigate } from "gatsby"
import React from "react"
import { Box, Flex } from "theme-ui"
import Image from "../components/base/image"
import Text from "../components/base/text/text"
import SEO from "../components/seo"


const Container = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

const Story = styled(Flex)`
  flex: 1 0 48%;
  flex-direction: column;
  max-width: 50%;
  cursor: pointer;

  ${(props) => props.theme.bp.desktop} {
    flex: 1 0 30%;
    max-width: 33%;
  }
`

const Stories = ({ data }) => {
  const { stories } = data

  return (
    <Container mx={["1%", "12%"]}>
      <SEO
        title={"Stories — Palmes"}
        description={"Palmes Tennis Society"}
      />
      {stories &&
        stories.nodes.map((s) => {
          return (
            <Story key={s.id} onClick={() => navigate(`/stories/${s.slug}`)}>
              <Flex sx={{ flex: 2, marginBottom: 1 }}>
                <Image
                  primary={s?.thumbnail}
                  onClick={() => navigate(`/stories/${s.slug}`)}
                />
              </Flex>
              <Flex
                sx={{
                  height: ["80px", "120px"],
                  flexDirection: "column",
                  marginBottom: "16px",
                }}
              >
                <Text sx={{ fontSize: [2, 3] }}>{s.title}</Text>
                <Text sx={{ fontSize: [1, 2] }}>{s.description}</Text>
                <Text sx={{ fontSize: [0, 1] }}>{s.date}</Text>
              </Flex>
            </Story>
          )
        })}
    </Container>
  )
}

export default Stories

export const pageQuery = graphql`
  query {
    stories: allContentfulStory(
      filter: { contentful_id: { ne: "5IZJt9FvPms5qeNocHE57Y" } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        id
        title
        description
        date(formatString: "LL")
        slug
        thumbnail {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`
